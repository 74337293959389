/* WF_1219233 sale checkbox */
/* see also productGridFilter.js */
.ab-sale__checkbox {
    display: none;
}
@media (min-width: 768px) {
    .ab-sale .ab-sale__checkbox {
        display: inline-block !important;
    }
}


/* ratings wf_1690637 */
.ab-ratings-headline,
.pds-ratings, .pds-rating-grade {
    display:none;
}
.pds-rating-grade {
    position: relative;
}
.pds-rating-grade__badge {
    position: absolute;
    right:1rem;
    top:50%;
    transform: translateY(-50%);
    display: block;
    width:3rem;
    height:3rem;
}
.pds-rating-grade__badge>img {
    display: block;
    width:3rem;
    height:3rem;
}
.rating-stars {
    position: relative;
    top:.125rem;
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: 1.5rem;
    font-family: Times;
    line-height: 1;
}
.rating-stars:before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, #fc0 var(--percent), #ccc var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
@media (max-width: 767px) {
    .ab-show-ratings .pds-ratings,
    .ab-show-ratings .pds-benefits,
    .ab-show-rating-grade .pds-ratings,
    .ab-show-rating-grade .pds-benefits,
    .ab-show-rating-grade .pds-rating-grade {
        padding:.75rem 1.125rem;
        border:1px solid var(--color-light-grey);
    }
    .ab-show-rating-grade .pds-benefits,
    .ab-show-ratings .pds-benefits {
        margin-bottom:-1px !important;
        margin-top: -.75rem !important;
    }
    .ab-show-rating-grade .ab-ratings-headline,
    .ab-show-ratings .ab-ratings-headline,
    .ab-show-ratings .pds-ratings {
        display:block;
    }
    .ab-show-rating-grade .pds-rating-grade {
        padding-right:4.5rem;
    }
    .ab-show-rating-grade .pds-rating-grade {
        display:block;
        margin-top:-1px !important;
    }
    .ab-show-rating-grade .pds-payment:not(.mr-3) {
        display: none;
    }
}


/* mobile preisdarstellung wf_1690633 */
.ab-mobile-price__show {
    display: none;
}
@media (max-width: 767px) {
    .ab-mobile-price .ab-mobile-price__show {
        display: block;
    }
    .ab-mobile-price .ab-mobile-price__hide {
        display: none;
    }
}
.ab-mobile-price-divider {
    padding-left:1rem;
    margin-left:1rem;
    border-left:1px solid var(--color-text-default);
}


/* pds detail accordion wf_1690635 */
.pds-detail-accordion {
    display: none;
    padding-bottom: 1.5rem;
}
.ab-mobile-accordion__show {
    display: none;
}
@media (max-width: 767px) {
    .ab-mobile-accordion .ab-mobile-accordion__show {
        display: inline-block;
    }
    .ab-mobile-accordion .collapse {
        scroll-margin-top: 10rem;
    }
    .ab-mobile-accordion .ab-mobile-accordion__hide {
        display: none;
    }
    .ab-mobile-accordion .pds-detail-accordion {
        display: block;
    }
    .ab-mobile-accordion .pds-detail-accordion .accordion__header-link .row.py-4 {
        padding-top:1rem !important;
        padding-bottom:1rem !important;
    }
    .ab-mobile-accordion .pds-detail-accordion .accordion__header-link .row.py-4 .fz-16 {
        font-size:15px !important;
    }
    .ab-mobile-accordion .pds-nav-tabs-wrapper,
    .ab-mobile-accordion .pds-nav-tabs-wrapper + .tab-content {
        display: none;
    }
}



/* mobile grid wf_1240576 */
@media (max-width: 767px) {
    .ab-mobile-grid .js-product-grid__content .row-gutter--3 {
        margin-left: -1rem;
        margin-right: -1rem;
    }
    .ab-mobile-grid .js-product-grid__content .row-gutter--3>[class*=" col"],
    .ab-mobile-grid .js-product-grid__content .row-gutter--3>[class^=col] {
        padding-left: 0.125rem;
        padding-right: 0.125rem;
    }
    .ab-mobile-grid .product-teaser {
        padding: 0;
        background: transparent;
    }
    .ab-mobile-grid .product-teaser__detail {
        padding:.25rem .875rem .625rem .875rem;
    }
    .ab-mobile-grid .product-teaser__detail .mb-3 {
        margin-bottom: 0rem !important;
    }
    .ab-mobile-grid .product-teaser__detail .mt-3 {
        margin-top: .5rem !important;
    }
    .ab-mobile-grid .product-teaser__detail .mt-auto {
        margin-top: 0 !important;
    }
    .ab-mobile-grid .product-teaser .embed-responsive {
        background: #fff;
    }
    .ab-mobile-grid .product-teaser .embed-responsive {
        background: #fff;
    }
    .ab-mobile-grid .product-teaser__additional {
        bottom: 1.875rem;
    }
}


/* gabor size wf_1690634 */
.ab-gaborsize__show{
    display: none;
}
.ab-gaborsize .ab-gaborsize__show {
    display: inline;
}
.ab-gaborsize .ab-gaborsize__hide {
    display: none;
}
.ab-gaborsize .size-selector__label {
    min-width: 4.375rem;
}


/* cart-delivery date wf_1632779 */
.ab-cart-delivery__show,
.ab-cart-delivery__show-days,
.ab-cart-delivery__show-date{
    display: none;
}
.ab-cart-delivery-days .ab-cart-delivery__show,
.ab-cart-delivery-date .ab-cart-delivery__show {
    display: block;
}
.ab-cart-delivery-days .ab-cart-delivery__show-days {
    display: inline;
}
.ab-cart-delivery-date .ab-cart-delivery__show-date {
    display: inline;
}


/* filter button text wf_1555295 */
.ab-filterbutton-text__show {
    display: none;
}
.ab-filterbutton-text .ab-filterbutton-text__show {
    display: inline;
}
.ab-filterbutton-text .ab-filterbutton-text__hide{
    display: none;
}


/* lieferdatum WF_1610939 */
.ab-delivery-date__show {
    display: none;
}
@media (max-width: 767px) {
    .ab-delivery-date .ab-delivery-date__hide {
        display: none;
    }
    .ab-delivery-date .ab-delivery-date__show{
        display: inline;
    }
}


/* cart  usps wf_1551536 */
.ab-cart-usp__show {
    display: none;
}
.ab-cart-usp .ab-cart-usp__show {
    display: flex;
    font-size: .875rem;
}
.ab-cart-usp .ab-cart-usp__hide{
    display: none;
}
@media (max-width: 767px) {
    .ab-cart-usp .sticky-box {
        margin-top:3rem;
    }
}


/* topbar usp items wf_1551532 */
.ab-usp__show {
    display: none;
}
.ab-usp .ab-usp__show {
    display: inline-flex;
}
.ab-usp .ab-usp__hide{
    display: none;
}


/* grid items WF_1114171 */
.ab-grid-cols .grid-col {
    @media (min-width: 1440px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
}


@media (max-width: 767px) {
    .ab-guest .ab-guest__hide {
        display: none !important;
    }
    .ab-guest .ab-guest__show {
        display: block !important;
    }
}