.box-selector__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.box-selector__label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: calc(80rem/16);
    min-height: calc(40rem/16);
    padding: calc(7rem/16) calc(4rem/16);
    border: 1px solid var(--color-medium-grey);
    cursor: pointer;
    font-size: calc(12rem/16);
    line-height: 1;
}
.box-selector__label:hover {
    background: var(--color-light-grey);
}
.box-selector__input:checked ~ .box-selector__label {
    border-color: var(--color-primary);
}

.box-selector__input:disabled ~ .box-selector__label.box-selector__label {
    color: var(--color-medium-grey);
    pointer-events: none;
}
.box-selector__sub-label {
    display:block;
    margin-top: calc(2rem/16);
    font-size: calc(10rem/16);
    color: var(--color-dark-grey);
    @media screen and (max-width: 767px) {
        font-size: calc(9rem/16);
    }
}
.box-selector__input:checked ~ .box-selector__label > .box-selector__sub-label {
    font-family: var(--font-default);
}


/* color box-selector */
.box-selector--color .box-selector__label {
    align-items: start;
    padding-left:1.625rem;
    font-size:calc(12rem / 16);
    @media screen and (max-width: 767px) {
        font-size:calc(11rem / 16);
    }
}
.box-selector--color .color-dot {
    pointer-events: none;
    position: absolute;
    left:calc(5rem/16);
    top:1px;
    bottom:1px;
    width:.875rem;
    height:auto;
    display:block;
    background:#fff;
    border:0;
    border-right:1px solid var(--color-medium-grey);
}
.box-selector--color .color-dot--colorful{
    background: linear-gradient(to right, red, yellow, lime, aqua, blue, magenta, red);
    background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
}

.box-selector--color .color-dot--colorful-simple{
    background: linear-gradient(to right, #4169e1, #8b592b, #9932cc);
    background: conic-gradient(#4570f0, #af7036, #ab38e4, #808080, #008b00);
}